function score(target, query, fuzzyFactor) {
    'use strict';
    // If the string is equal to the word, perfect match.
    if (target === query) {
        return 1;
    }

    // If it's not a perfect match and is empty return 0.
    if (query === '') {
        return 0;
    }

    var runningScore = 0;
    var targetLower = target.toLowerCase();
    var targetLen = target.length;
    var queryLower = query.toLowerCase();
    var queryLen = query.length;
    var startAt = 0;
    var fuzzies = 1;

    // Calculate fuzzy factor
    fuzzyFactor = fuzzyFactor ? 1 - fuzzyFactor : 0;

    // Walk through query and add up scores.
    // Code duplication occurs to prevent checking fuzziness inside for loop
    for (var i = 0; i < queryLen; i+=1) {
        // Find next first case-insensitive match of a character.
        var idxOf = targetLower.indexOf(queryLower[i], startAt);

        if (idxOf === -1) {
            if (fuzzyFactor) {
                fuzzies += fuzzyFactor;
            } else {
                return 0;
            }
        } else {
            var charScore = 0;
            if (startAt === idxOf) {
                // Consecutive letter & start-of-string Bonus
                charScore = 0.7;
            } else {
                charScore = 0.1;

                // Acronym Bonus
                // Weighing Logic: Typing the first character of an acronym is as if you
                // preceded it with two perfect character matches.
                if (target[idxOf - 1] === ' ') {
                    charScore += 0.8;
                }
            }

            // Same case bonus.
            if (target[idxOf] === query[i]) {
                charScore += 0.1;
            }

            // Update scores and startAt position for next round of indexOf
            runningScore += charScore;
            startAt = idxOf + 1;
        }
    }

    // Reduce penalty for longer strings.
    var finalScore = 0.5 * (runningScore / targetLen + runningScore / queryLen) / fuzzies;

    if ((queryLower[0] === targetLower[0]) && (finalScore < 0.85)) {
        finalScore += 0.15;
    }

    return finalScore;
}
function titleCase(str) {
    try {
        return str.toLowerCase().split(' ').map(function (word) {
            if (word.length > 0)
                return word.replace(word[0], word[0].toUpperCase());
            return word;
        }).join(' ');
    }
    catch (e) {
        console.log("Error en busqueda",e);
        return str;
    }
}
Vue.component('headerSectionComponent', {
    props:['renderMenu','renderSearch','headerTitle'],
    data: function () {
        return {
            baget_in_car: 0,
            show_modal: true,
            filterValue: "",
            optionList:[]
        }
    },
    watch:{
        "filterValue":"reloadOption",
    },
    computed: {
        session: function () {
            return this.$store.getters.getSession;
        },
        menuVisible: function(){
            if(this.renderMenu==undefined || this.renderMenu==null)
                return true;
            else
                return this.renderMenu
        },
        inputSearch: function(){
            if(this.renderSearch==undefined || this.renderSearch==null)
                return true;
            else
                return this.renderSearch
        },

    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout');
            this.$router.push('/home');
        },
        openModalLogin: function () {
            $('#loginModal').modal();
            $('#loginModal').modal('open');
        },
        openModalRegister: function () {
            $('#registerModal').modal();
            $('#loginModal').modal('close');
            $('#registerModal').modal('open');
        },

        openModalChangePassword: function () {
            $('#changePasswordModal').modal();
            $('#changePasswordModal').modal('open');
        },
        openModalCustomer: () => {
            $('#registerCustomerModal').modal();
            $('#registerCustomerModal').modal('open');
        },
        filter(){
            this.optionList = []
            let searchWords = this.filterValue.toString().toLowerCase()
            let synonym = this.$store.getters.trSynonyms(this.filterValue.toString());
            console.log('Synoms',synonym,searchWords);
            let filter = {}
            filter.name = 'inputSearch-'+uuidv4();
            filter.type = `inputSearch`;
            let settings = this.$store.getters.getSetting;
            filter.condition="";
            let filterFields=['Name','Code','Comment'];
            if(settings.SearchFields)
                filterFields = settings.SearchFields.split(",");
            let isFirts=true;
            let itemTemplate = this.$store.state.items_store.values().next().value;
            for(let filterField of filterFields){
                if(itemTemplate.hasOwnProperty(filterField)) {
                    //console.log("Tipo de Campo",typeof itemTemplate[filterField]);
                    //Se puede mejorar para evalua según tipo de valor.
                    if (!isFirts) {
                        filter.condition += " || "
                    }
                    filter.condition += `( x.${filterField} && ( (x.${filterField}.toString().toLowerCase().includes('${searchWords}') || x.${filterField}.toString()=='${searchWords}' ) `;
                    if (synonym) {
                        filter.condition += ` || `
                        filter.condition += `( x.${filterField}.toString().toLowerCase().includes('${synonym}') || x.${filterField}.toString()=='${synonym}' ) `;
                    }
                    isFirts = false;
                    filter.condition += ` ) ) `
                }
            }
            /*}
            else {
                if (synonym)
                    filter.condition = `x.Name x.Name.toLowerCase().includes('${searchWords}') || x.Code=='${searchWords}' || x.Name.toLowerCase().includes('${synonym}')`;
                else
                    filter.condition = `x.Name.toLowerCase().includes('${searchWords}') || x.Code=='${searchWords}'`;
            }*/
            //console.log("FILTER QUERY",filter.condition);
            filter.result = true;
            this.$store.dispatch('addGlobalFilter',filter);
            this.$store.dispatch('updateCategory',null);
            this.$router.push('/items');
            this.filterValue="";
        },
        testEvent:function(){
            //console.log('test Input event')
        },
        reloadOption:function (event) {
            let synonym = this.$store.getters.trSynonyms(this.filterValue.toString());
            //console.log("Sinónimos",synonym);
            if(this.filterValue.length<3)
                return;
            else {
                let newoptionList = [];

                for (let item of this.$store.state.items_store.values()) {
                    try {
                        if (synonym) {
                            if (item.Name.toLowerCase().startsWith(synonym.toLowerCase()))
                                newoptionList.push(titleCase(item.Name))
                        }
                        if (item.Name.toLowerCase().startsWith(this.filterValue.toLowerCase()))
                            newoptionList.push(titleCase(item.Name))
                        if (newoptionList.length > 10)
                            break;
                    }
                    catch (e) {
                        console.log('Error in item',item,e)
                    }
                }
                if(newoptionList.length==1 && newoptionList[0].toLowerCase()==this.filterValue.toLowerCase())
                    this.optionList = []
                else
                    this.optionList = newoptionList.slice(0,10);
            }
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            $('#reportModal').modal();
        })
    },
    template: `<div class="main-header">
                    <changePasswordModalComponent />
                    <headerReportModal></headerReportModal>
                    <nav>
                        <div class="container">
                            <div class="nav-wrapper row">
                                <router-link href="#" :to="'/home'">
                                    <img class="left responsive-img" src="../static/ecommerce/assets/img/logo.jpg" />
                                </router-link>
                                <template v-if="inputSearch">
                                    <div class="input-search col s6 offset-s1" >
                                        <form autocomplete="off" onkeydown="return event.key != 'Enter';">
                                        <input list="searchinput" type="text" class="inputSEARCH ui-autocomplete-input" name="input-term"  @keydown="reloadOption"  @change="testEvent" @keydown.enter="filter()" id="robot" placeholder="BUSCAR" v-model="filterValue" value="" autocomplete="none">
                                            <datalist id="searchinput"  class="inputSEARCH ui-autocomplete-input">
                                                <template v-for="opt in optionList">
                                                    <option :value="opt" @click="alert('test')" />
                                                </template> 
                                            </datalist>
                                        <label for="search" class="search-icon"><i class="fas fa-search"></i></label>
                                        </form>
                                   </div>
                               </template>
                               <template v-else>
                                <div class="valign-wrapper" v-if="headerTitle" >
                                    <h5 class="black-text">{{headerTitle}}</h5>
                                </div>
                               </template>
                               <a class="button-collapse" href="#" data-activates="nav-mobile"><i class="mdi-navigation-menu"></i></a>
                            </div>
                        </div>
                    </nav>
                    <div class="header-menu container" v-if="menuVisible">
                        <ul id='reportList-dropdown' class='dropdown-content'>
                            <li><router-link v-if="$store.state.session" to="/invoices" ><i class="fas fa-angle-right"></i>{{tr("Invoice List").toUpperCase()}}</router-link></li>
                            <li><router-link v-if="$store.state.session" to="/report/AccReceivable" ><i class="fas fa-angle-right"></i>{{tr("Accounts Receivable").toUpperCase()}}</router-link></li>
                        </ul>
                        <ol class="breadcrumbs">
                            <router-link href="#" :to="'/home'"><i class="fas fa-angle-right"></i> INICIO </router-link>
                            <!--<router-link href="#" :to="'/items'"><i class="fas fa-angle-right"></i> ARTICULOS </router-link>-->
                            <router-link v-if="$store.state.session" to="/latestshopping/awaiting" ></i><i class="fas fa-angle-right"></i> ORDENES A AUTORIZAR </router-link>
                            <router-link v-if="$store.state.session" to="/latestshopping/all" ><i class="fas fa-angle-right"></i> TRACKING DE ORDENES</router-link>
                            <router-link v-if="$store.state.session" to="/favorities" ><i class="fas fa-angle-right"></i> LISTADO PERSONAL</router-link>
                            <router-link v-if="$store.state.session" to="/suggestions" ><i class="fas fa-angle-right"></i> LISTADO SUGERIDO</router-link>
                            <router-link v-if="$store.state.session" to="/gifts" ><i class="fas fa-angle-right"></i> FORMULARIOS</router-link>
                            <a v-if="$store.state.isAdmin" href="#reportModal" data-target="reportModal" class="modal-trigger"><i class="fas fa-angle-right"></i> REPORTES</a>
                            <router-link v-if="$store.state.isAdmin" to="/indicators" ><i class="fas fa-angle-right"></i> INDICADORES</router-link>                          
                        </ol>
                    </div>
                </div>`,
});
